/* mixins, variables, etc. */

$gray-medium-light: #eaeaea;
$base-font: 0.8em;

@mixin box_sizing {
  -moz-box-sizing:    border-box;
  -webkit-box-sizing: border-box;
  box-sizing:         border-box;
}

/* universal */

body {
  padding-top: 0px;
  padding-bottom: 20px;
}

section {
  overflow: auto;
}

textarea {
  resize: vertical;
}

.center {
  text-align: center;
}

.center h1 {
  margin-bottom: 10px;
}

/* typography */

h1, h2, h3, h4, h5, h6 {
  line-height: 1;
}

h1 {
  font-size: 1.7em;
  letter-spacing: -2px;
  margin-bottom: 20px;
  text-align: left;
}

h2 {
  font-size: 1.1em;
  letter-spacing: -1px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: normal;
  color: #777;
}

p, td {
  font-size: 0.85em;
  line-height: 1.5em;
}

/* header */

#logo {
  float: left;
  margin-right: 10px;
  font-size: 1.7em;
  color: #fff;
/*  text-transform: uppercase;*/
  letter-spacing: -1px;
/*  padding-top: 9px; */
  font-weight: bold;
}

#logo:hover {
  color: #fff;
  text-decoration: none;
}

/* forms */

input, textarea, select, .uneditable-input {
  border: 1px solid #bbb;
  width: 100%;
  margin-bottom: 15px;
  @include box_sizing;
}

select {
   background: transparent;
}

input {
  height: auto !important;
}

.auto-width {
  width: auto;
}

.bold-text {
  font-weight: bold;
}

.nowrap {
  white-space: nowrap;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.inline-div {
  display: inline-block;
  margin-bottom: 5px;
  padding: 0px 0px;
}

.link-button {
  padding: 6px 12px;
}

.link-button-disabled {
  pointer-events: none !important;
}


.nav-tabs-mine {
  margin-bottom: 10px;
}


ul.list-group:after {
  clear: both;
  display: block;
  content: "";
}

.list-group-item {
    float: left;
}

.table-outline > tbody > tr > td, .table-outline > tbody > tr > th {
  border: none;
}

.table-outline {
  border: 1px solid #ddd;
}

.table-checkbox > tbody > tr > td {
  padding-top: 0px;
  padding-bottom: 0px;
}


.input-group-xs>.form-control,
.input-group-xs>.input-group-addon,
.input-group-xs>.input-group-btn>.btn {
    height: 22px;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}

.glyphicon {
    top: 0;
}

.checkbox-sm {
  margin-top: 4px;
  margin-bottom: 4px;
}

.error-border {
  border-color: red;
}

.checkbox-aligned {
  vertical-align: middle !important;
  padding: 0px !important;
  margin: 0px 4px 0px 0px !important;
}

.checkbox-label-aligned {
  vertical-align: middle !important;
  padding: 0px !important;
  margin: 0px !important;
}
